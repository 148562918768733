import React, { useEffect, useState } from 'react'
import Head from 'next/head'
import Signup from '@/containers/Signup'

export default function LoginIndex() {
    const [isClient, setIsClient] = useState(false)
    useEffect(() => {
        setIsClient(true)
    }, [])
    
    return (
        <>
            <Head>
                <title>Log In - Hype</title>
                <meta property="og:title" content="Log In - Hype" key="title" />
            </Head>
            {isClient && (
                <Signup isLoginDefault={true} />
            )}
        </>
    )
}
